export type Question = { t: string; weight: number };

export const questions: Question[] = [
  {
    t: "Araç kullanırken, zaman zaman, camdan dışarıya plastik çöp / sigara paketi / peçete vs. atarım.",
    weight: 0.6,
  },
  { t: "Zaman zaman da olsa yerlere tükürürüm.", weight: 0.7 },
  {
    t: "Otobüs beklerken durakta sigara içerim; hatta tam binerken son nefesi çekip bir kısmını otobüs içine üflemeyi de ihmal etmem.",
    weight: 0.3,
  },
  {
    t: "İki kişinin evleniyor olması; çevredeki insanları, konvoy halinde, korna sesi ve yüksek müzikle rahatsız etmek için yeterli bir sebeptir.",
    weight: 0.3,
  },
  {
    t: "Toplu taşımada yüksek sesle konuşarak diğer yolcuların da beni duymasını sağlarım; hiç kimseyi merakta bırakmam.",
    weight: 0.3,
  },
  {
    t: "Piknik yeri ya da sahil gibi yerlerde karpuz kabuklarını, sigara izmaritlerini, bira şişelerini vs. her zaman çöpe atmam. Bazen orda bırakırım.",
    weight: 0.8,
  },
  {
    t: "Aracımda, insanları rahatsız edecek seviyede, yüksek seste müzik dinlerim. Bunun dakikalarca sürmesinden hicap duymam.",
    weight: 0.2,
  },
  { t: "Dişlerimi fırçalamam.", weight: 0.4 },
  {
    t: "Çok samimi olmadığım insanlara aylık gelirlerini ve evlerinin kira olup olmadığını sormaktan imtina etmem.",
    weight: 0.1,
  },
  {
    t: "Zaman zaman, şaka ve eğlence maksadıyla, beğendiğim bir karşı cinse laf atarım / sözlü tacizde bulunurum / rahatsızlık veririm.",
    weight: 1.0,
  },
  {
    t: "Yaya geçitlerinde yayaların geçmesine izin vermem, yol benimdir.",
    weight: 0.1,
  },
];
