import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { questions } from "./questions";
import "./styles.module.css";

function getShuffledArr<T>(arr: T[]): T[] {
  const newArr = arr.slice();
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  return newArr;
}

console.log(
  "Vakit kazandirayim biraz; Tech stack: React + Tailwind CSS + Parcel Bundler"
);
console.log(
  "Server side yok. Tamamen client. cookie mookie bi sey de yok. oyle dumduz site."
);

function App() {
  const [items] = useState(getShuffledArr(questions));
  const [currIndex, setCurrIndex] = useState(0);
  const [egitimsizMi, setEgitimsizMi] = useState(false); // bunun defaultu true aq. nereye false. neyse insanlik bende kalsin...
  const [id, setId] = useState<number>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const _id = parseInt(location.search.split(/\?m=/)[1], 10);
    if (_id && _id <= questions.length) {
      setId(_id);
      document.title = `Eğitimsiz miyim? (Madde: ${_id})`;
    }
    setReady(true);
  }, []);

  function handleClickTweet(e: any) {
    e.preventDefault();
    const a = document.createElement("a");
    a.setAttribute(
      "href",
      "https://twitter.com/intent/tweet?url=&text=%23egitimsizsiniz"
    );
    a.setAttribute("target", "_blank");
    a.click();
  }

  function handleNo() {
    if (currIndex + 1 === items.length) {
      console.log("bitti");
    } else {
      setCurrIndex((c) => c + 1);
    }
  }

  function handleYes() {
    setEgitimsizMi(true);
  }

  function share(e: any) {
    e.preventDefault();
    const index = questions.findIndex(q => q.t === items[currIndex].t)
    if (navigator.share) {
      navigator
        .share({
          title: `Eğitimsiz miyim? (Madde: ${index + 1})`,
          text: `Eğitimsiz miyim? (Madde: ${index + 1})`,
          url: `https://egitimsizmiyim.com?m=${index + 1}`,
        })
        .then(() => {
          console.log("Paylastik. <3");
        })
        .catch(console.log);
    } else {
      window.location.href = `/?m=${index + 1}`;
    }
  }

  let content;

  if (!egitimsizMi && currIndex + 1 === items.length) {
    content = (
      <h1 className="text-5xl font-bold bg-green-500 text-white px-4 py-3 text-center">
        GALİBA DEĞİLSİN
      </h1>
    );
  } else if (egitimsizMi) {
    content = (
      <>
        <h1 className="text-7xl mb-10 text-red-500">¯\_(ツ)_/¯</h1>
        <h1 className="text-5xl font-bold bg-red-500 text-white px-4 py-3 text-center">
          MUHTEMELEN
        </h1>
      </>
    );
  } else {
    if (id) {
      content = (
        <>
          <h3 className="text-3xl text-gray-600 underline">Madde #{id}</h3>
          <h1 className="text-4xl p-2 text-center big-text">
            {questions[id - 1].t}
          </h1>
        </>
      );
    } else {
      content = (
        <>
          <h1 className="text-4xl p-2 text-center big-text">
            {items[currIndex].t}{" "}
            <a onClick={share} href="#">
              🔗
            </a>
          </h1>
          <div className="mt-7">
            <button
              onClick={handleNo}
              className="w-32 mr-10 py-2 px-4 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none"
            >
              HAYIR
            </button>
            <button
              onClick={handleYes}
              className="w-32 py-2 px-4 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 focus:outline-none"
            >
              EVET
            </button>
          </div>
        </>
      );
    }
  }

  return (
    <div className="flex flex-col h-full bg-yellow-200 items-center justify-between p-2 pt-5">
      <h1
        className="text-6xl title hover:underline cursor-pointer"
        onClick={() => (window.location.href = "/")}
      >
        Eğitimsiz miyim?
      </h1>
      <div className="flex flex-col items-center">{ready ? content : null}</div>
      <footer className="p-2 text-center ">
        <small>
          Listeye eklenmesi için önerilerinizi{" "}
          <a
            href={"https://twitter.com"}
            onClick={handleClickTweet}
            target="_blank"
            className="text-blue-500"
          >
            #egitimsizsiniz
          </a>{" "}
          hashtagine yazabilirsiniz.
        </small>
      </footer>
    </div>
  );
}

render(<App />, document.getElementById("root"));
